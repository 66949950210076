/*

Known Template 

https://templatemo.com/tm-516-known

*/

  /*@import url('https://fonts.googleapis.com/css?family=Muli:300,700|Nunito');*/
  @import url('https://fonts.googleapis.com/css?family=Montserrat');

  body {
    background: #ffffff;
    font-family: 'Montserrat', sans-serif !important;
    overflow-x: hidden;
    padding-top: 70px;
  }


  /*---------------------------------------
     TYPOGRAPHY              
  -----------------------------------------*/

  h1,h2,h3,h4,h5,h6 {
    /*font-family: 'Muli', sans-serif;*/
    font-weight: bold;
    line-height: inherit;
  }

  h1 {
    color: #252525;
    font-size: 3em;
    line-height: normal;
  }

  h2 {
    color: #353535;
    font-size: 2em;
    padding-bottom: 10px;
  }

  h3 {
    font-size: 1.5em;
    margin-bottom: 0;
  }

  h3,
  h3 a {
    color: #454545;
  }

  p {
    color: #757575;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
  }



  /*---------------------------------------
     GENERAL               
  -----------------------------------------*/

  html{
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: #252525;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none !important;
  }

  a,
  input, button,
  .form-control {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  a:hover, a:active, a:focus {
    color: #29B6F6;
    outline: none;
  }

  ::-webkit-scrollbar{
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #000000;
  }

  /*.section-title {
    padding-bottom: 40px;
  }*/

  .section-title h2 {
    margin: 0;
  }

  .section-title small {
    display: block;
  }

  .overlay {
    background: rgba(20,20,20,0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .entry-form {
    background: #2C3E50;
    border-radius: 100%;
    text-align: center;
    padding: 6em;
    width: 450px;
    height: 450px;
  }

  @media screen and (min-width: 1024px) {
    .entry-form#form_candidate {
      background: #2C3E50;
      border-radius: 100%;
      text-align: center;
      padding: 6em;
      width: 510px;
      height: 510px;
    }
  }

  .entry-form h2 {
    color: #ffffff;
    margin: 0;
  }

  .entry-form .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    box-shadow: none;
    height: 45px;
    margin: 10px 0;
    color: #ffffff;
  }

.entry-form .form-control > option {
  color: #000;
}

  .entry-form .submit-btn {
    background: #ffffff;
    border-radius: 50px;
    border: 0;
    color: #2C3E50;
    width: 50%;
    height: 50px;
    margin: 30px auto;
    margin-bottom: 10px;
  }

  .entry-form .submit-btn:hover {
    background: #3f51b5;
    color: #ffffff;
  }

  section {
    position: relative;
    padding: 100px 0;
  }

  #team,
  #posts {
    background: #f9f9f9;
  }

  #team, 
  #posts {
    text-align: center;
  }
  
  #google-map iframe {
    border: 0;
    width: 100%;
    height: 390px;
  }



  /*---------------------------------------
     BUTTONS               
  -----------------------------------------*/

  .section-btn {
    background: transparent;
    border-radius: 50px;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: inherit;
    font-weight: normal;
    padding: 15px 30px;
    transition: 0.5s;
  }

  .section-btn:hover {
    background: #ffffff;
    border-color: transparent;
  }



  /*---------------------------------------
       PRE LOADER              
  -----------------------------------------*/

  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: none repeat scroll 0 0 #ffffff;
    flex-direction: column;
  }

  .spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 100px auto;
  }

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #29B6F6;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
  }

  /*---------------------------------------
      MENU              
  -----------------------------------------*/

  .custom-navbar {
    background: #ffffff;
    /*border-top: 5px solid #29B6F6;*/
    border-top: 1px solid #29B6F6;
    border-bottom: 0;
    -webkit-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    /*padding: 12px 0;*/
    margin-bottom: 0;
    padding: 0;
    font-weight: bold;
  }

  .custom-navbar .navbar-brand {
    color: #454545;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
  }

  .custom-navbar .navbar-nav.navbar-nav-first {
    margin-left: 8em;
    /*border-right: #c3c3c3 2px solid;*/
  }

  .custom-navbar .navbar-nav.navbar-right li a {
    padding-right: 12px;
    padding-left: 12px;
    font-weight: normal;
    font-size: 14px;
  }

  .custom-navbar .navbar-nav.navbar-right li a .fa {
    background: #29B6F6;
    border-radius: 100%;
    color: #ffffff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    margin-right: 5px;
  }

  .custom-navbar .nav li a {
    line-height: 40px;
    color: #575757;
    padding-right: 22px;
    padding-left: 22px;
  }

  .custom-navbar .navbar-nav > li > a:hover,
  .custom-navbar .navbar-nav > li > a:focus {
    background-color: transparent;
  }

  .custom-navbar .nav li a:hover {
    background-color: #29B6F6;
    color: #ffffff;
  }

  .custom-navbar .nav li.active > a {
    background-color: #29B6F6;
    color: #ffffff;
  }

  .custom-navbar .navbar-toggle {
    border: none;
    padding-top: 10px;
  }

  .custom-navbar .navbar-toggle {
    background-color: transparent;
  }

  .custom-navbar .navbar-toggle .icon-bar {
    background: #252525;
    border-color: transparent;
  }



  /*---------------------------------------
      HOME  & SLIDER            
  -----------------------------------------*/

  #home {
    padding: 0;
  }

  #home h1 {
    color: #ffffff;
    background-color: #2C3E50;
    padding: 20px;
  }

  #home h3 {
    /*color: #f9f9f9;*/
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 5px 0 40px 0;
  }

  @media (min-width: 768px) {
    .home-slider .col-md-6 {
      padding-left: 0;
    }
  }

  .home-slider .caption {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    /*background-color: rgba(20, 20, 20, 0.4);*/
    height: 100%;
    color: #fff;
    cursor: e-resize;
  }

  .home-slider .item {
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: cover;
    height: 465px;
  }
  
  .caption h3 a { color: #FFF; }
  .caption h3 a:hover { color: #FF3; }

  .home-slider .item-first {
    /*background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("../images/slider-1.jpg");*/
    /*background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7));*/
    background-image: url(../images/slider-1.jpg);
	background-position: center;
  }

  .home-slider .item-second {
    background-image: url(../images/slider-2.jpg);
  }

  .home-slider .item-third {
    background-image: url(../images/slider-3.jpg);
  }



  /*---------------------------------------
      FEATURE              
  -----------------------------------------*/

  .feature-thumb {
    border: 1px solid #f0f0f0;
    padding: 5em 3em;
  }

  .feature-thumb span {
    background: #3f51b5;
    border-radius: 50px;
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 5px;
  }

  .feature-thumb h3 {
    margin: 10px 0;
  }



  /*---------------------------------------
      ABOUT              
  -----------------------------------------*/

  #depose-cv figure {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
  }

  #depose-cv figure span {
    float: left;
    margin-left: -15px;
    padding: 15px 20px;
    position: relative;
    top: 20px;
  }

  #depose-cv figure span i {
    background: #2C3E50;
    border-radius: 50px;
    color: #ffffff;
    font-size: 25px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }



  /*---------------------------------------
      TEAM              
  -----------------------------------------*/

  .team-thumb {
    background: #ffffff;
    position: relative;
    overflow: hidden;
    text-align: left;
  }

  .team-info {
    padding: 20px 30px;
  }

  .team-image img {
    width: 100%;
  }

  .team-thumb .social-icon {
    border-top: 1px solid #f0f0f0;
    padding: 4px 20px 0 20px;
  }

  .team-thumb .social-icon li a {
    background: #ffffff;
    color: #252020;
  }

  .team-info h3 {
    margin: 0;
  }


  /*---------------------------------------
      COURSES             
  -----------------------------------------*/

  #job-list .section-title {
    text-align: center;
  }

  #job-list .owl-theme .owl-nav {
    margin-top: 30px;
  }

  #job-list .owl-theme .owl-nav [class*=owl-] {
    border-radius: 2px;
    font-size: 16px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }

  .courses-thumb {
    background: #f9f9f9;
    position: relative;
  }

  .courses-top {
    position: relative;
  }

  .courses-image {
    background: linear-gradient(to right, #202020, #101010);
  }

  .courses-date {
    background: linear-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.6));
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px 25px;
  }

  .courses-date span,
  .courses-author span {
    font-size: 14px;
    font-weight: bold;
  }

  .courses-date span {
    color: #ffffff;
    display: inline-block;
    margin-right: 10px;
  }

  .courses-detail {
    padding: 25px 25px 15px 25px;
  }

  .courses-detail h3 {
    margin: 0 0 2px 0;
  }

  .courses-info {
    border-top: 1px solid #f0f0f0;
    position: relative;
    clear: both;
    padding: 15px 25px;
  }

  .courses-author,
  .courses-author span {
    display: inline-block;
    vertical-align: middle;
  }

  .courses-author img {
    display: inline-block !important;
    border-radius: 50px;
    width: 50px !important;
    height: 50px;
    margin-right: 10px;
  }

  .courses-price {
    float: right;
    margin-top: 10px;
  }

  .courses-price span {
    background: #29B6F6;
    border-radius: 2px;
    color: #ffffff;
    display: block;
    padding: 5px 10px;
  }

  .courses-price.free span {
    background: #3f51b5;
  }



  /*---------------------------------------
     NEWS              
  -----------------------------------------*/

  .news-thumb {
    clear: both;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .news-thumb .news-image {
    float: left;
    width: 40%;
    margin-right: 30px;
  }

  .news-info h3 {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .news-date span {
    color: #909090;
    font-weight: bold;
  }



  /*---------------------------------------
      TESTIMONIAL             
  -----------------------------------------*/

  #posts .item {
    background: #ffffff;
    margin: 20px 0;
    padding: 4em 3em;
    text-align: left;
  }

  #job-list .col-md-4,
  #posts .col-md-4 {
    display: block;
    /*width: 100%;*/
  }

  #posts .item > p {
    font-size: 16px;
    line-height: 26px;
  }

  .tst-rating {
    margin-bottom: 15px;
  }

  .tst-rating .fa {
    color: #3f51b5;
  }

  .tst-image,
  .tst-author {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px;
    text-align: left;
  }

  .tst-image img {
    border-radius: 50px;
    width: 60px !important;
    height: 60px;
    margin-right: 15px;
  }

  .tst-author h4 {
    margin: 0;
  }

  .tst-author span {
    color: #808080;
    font-size: 14px;
  }



  /*---------------------------------------
      CONTACT             
  -----------------------------------------*/

  #contact {
    background: #3f51b5;
  }

  #contact h2 {
    color: #ffffff;
  }

  #contact .section-title {
    padding-bottom: 20px;
  }

  #contact h2 > small,
  #contact p,
  #contact a {
    color: #f9f9f9;
  }

  #contact-form {
    padding: 1em 0;
  }

  #contact-form .col-md-12,
  #contact-form .col-md-4 {
    padding-left: 0;
  }

  #contact-form .form-control {
    border: 0;
    border-radius: 5px;
    box-shadow: none;
    margin: 10px 0;
  }

  #contact-form input {
    height: 50px;
  }

  #contact-form input[type='submit'] {
    border-radius: 50px;
    border: 1px solid transparent;
  }

  #contact-form input[type='submit']:hover {
    background: transparent;
    border-color: #ffffff;
    color: #ffffff;
  }
  



  /*---------------------------------------
     FOOTER              
  -----------------------------------------*/

  footer {
    background: #252020;
    padding: 60px 0;
    /*padding: 120px 0;*/
  }

  footer .section-title {
    padding-bottom: 10px;
  }

  footer h2 {
    font-size: 20px;
  }

  footer a,
  footer p {
    color: #909090;
  }

  footer a:hover {
    color: #29B6F6;
  }

  footer strong {
    color: #d9d9d9;
  }

  footer address p {
    margin-bottom: 0;
  }

  footer .social-icon {
    margin-top: 25px;
  }

  .footer-info {
    margin-right: 2em;
  }

  .footer-info h2 {
    color: #ffffff;
    padding: 0;
  }

  .footer_menu h2 {
    margin-top: 2em;
  }

  .footer_menu ul {
    margin: 0;
    padding: 0;
  }

  .footer_menu li {
    display: inline-block;
    list-style: none;
    margin: 5px 10px 5px 0;
  }

  .newsletter-form .form-control {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #303030;
    height: 50px;
    margin: 5px 0;
    color: #ffffff;
  }

  .newsletter-form input[type="submit"] {
    background: transparent;
    border: 1px solid #f9f9f9;
    border-radius: 50px;
    color: #ffffff;
    display: block;
    margin-top: 20px;
    outline: none;
    width: 50%;
  }

  .newsletter-form input[type="submit"]:hover {
    /*background: #29B6F6;*/
    background: #29B6F6;
    border-color: transparent;
  }

  .newsletter-form .form-group span {
    color: #909090;
    display: block;
    font-size: 12px;
    margin-top: 20px;
  }



  /*---------------------------------------
     SOCIAL ICON              
  -----------------------------------------*/

  .social-icon {
    position: relative;
    padding: 0;
    margin: 0;
  }

  .social-icon li {
    display: inline-block;
    list-style: none;
    margin-bottom: 5px;
  }

  .social-icon li a {
    border-radius: 100px;
    /*color: #29B6F6;*/
    color: #29B6F6;
    font-size: 15px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-decoration: none;
    text-align: center;
    transition: all 0.4s ease-in-out;
    position: relative;
    margin: 5px 5px 5px 0;
  }

  .social-icon li a:hover {
    /*background: #29B6F6;*/
    background: #29B6F6;
    color: #ffffff;
  }



  /*---------------------------------------
     RESPONSIVE STYLES              
  -----------------------------------------*/

  @media screen and (max-width: 1170px) {
    .custom-navbar .navbar-nav.navbar-nav-first {
      margin-left: inherit;
    }
  }

  @media only screen and (max-width: 992px) {
    section,
    footer {
      padding: 60px 0;
    }

    .home-slider .item {
      background-position: center center;
    }

    .feature-thumb,
    .about-info,
    .team-thumb,
    .footer-info {
      margin-bottom: 50px;
    }

    .contact-image {
      margin-top: 50px;
    }
  }


  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .custom-navbar .nav li a {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 2.5em;
    }

    h1,h2,h3 {
      line-height: normal;
    }

    .custom-navbar {
      background: #ffffff;
      -webkit-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
      padding: 10px 0;
      text-align: center;
    }

    .custom-navbar .navbar-brand,
    .custom-navbar .nav li a {
      line-height: normal;
    }

    .custom-navbar .nav li a {
      padding: 10px;
    }

    .custom-navbar .navbar-brand,
    .top-nav-collapse .navbar-brand {
      color: #252525;
      font-weight: 600;
    }

    .custom-navbar .nav li a,
    .top-nav-collapse .nav li a {
      color: #575757;
    }

    .custom-navbar .navbar-nav.navbar-right li {
      display: inline-block;
    }

    .footer-info {
      margin-right: 0;
    }

    .footer-info.newsletter-form {
      margin-bottom: 0;
    }

    .entry-form {
      display: block;
      margin: 0 auto;
    }
  }


  @media only screen and (max-width: 580px) {
    h2 {
      font-size: 1.8em;
    }

    #posts .item {
      padding: 2em;
    }

    .contact-image {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 2em;
    }

    #home h3 {
      font-size: 14px;
    }

    .entry-form {
      border-radius: 0;
      padding: 2em;
      max-width: 100%;
      max-height: 100%;
      width: inherit;
      height: inherit;
    }
  }

section{
  padding: 60px 0;
}
section .section-title-custom {
  text-align:center;
  color:#29B6F6;
  /*margin-bottom:50px;*/
  text-transform:uppercase;
}
#what-we-do{
  background:#ffffff;
}
#what-we-do .card{
  padding: 1rem!important;
  border: none;
  margin-bottom:1rem;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}
#what-we-do .card:hover{
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}
#what-we-do .card .card-block{
  padding-left: 50px;
  position: relative;
}
#what-we-do .card .card-block a{
  color: #29B6F6 !important;
  font-weight:700;
  text-decoration:none;
}
#what-we-do .card .card-block a i{
  display:none;

}
#what-we-do .card .card-block h3{
  margin-top: 20px;
  margin-bottom: 10px;
}
#what-we-do .card:hover .card-block a i{
  display:inline-block;
  font-weight:700;

}
#what-we-do .card .card-block:before{
  font-family: FontAwesome;
  position: absolute;
  font-size: 39px;
  color: #29B6F6;
  left: 0;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition:transform .2s ease-in-out;
}
#what-we-do .card .block-1:before{
  content: "\f234"; /** \f0c0 */
}
#what-we-do .card .block-2:before{
  content: "\f0ea";
}
#what-we-do .card .block-3:before{
  content: "\f002"; /** \f00c */
}
#what-we-do .card .block-4:before{
  content: "\f183"; /** "\f0e7" */
}
#what-we-do .card .block-5:before{
  content: "\f155";
}
#what-we-do .card .block-6:before{
  content: "\f073";
}
#what-we-do .card:hover .card-block:before{
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}

#logo {
  background-image: url(../images/logo_human_talent_consulting.svg);
  background-repeat: no-repeat;
  width: 205px;
  height: 60px;
  background-size: contain;
  margin-top: 3px;
}

.partners {
    /*padding: 40px 0;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.partners-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 160px;
  flex: 0 0 160px;
  padding: 30px 30px;
}

.presentation-video {
  height: 290px;
  max-width: 920px;
  margin: 0 auto;
  background-color: #F5F5F5;
  -webkit-box-shadow: 5px 5px 15px 10px #E3E3E3;
  box-shadow: 5px 5px 15px 10px #E3E3E3;
  background-size: 100% 100%;
  cursor: pointer;
}

.item-gallery {
  margin-bottom: 25px;
  /*box-shadow: 0 0 15px rgba(0,0,0,.1);*/
}

.text-slider {
  padding: 10px; letter-spacing: 1px;
}

/* common */
.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold; line-height: 20px;
  transform: rotate(45deg);
  width: 75px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 30px;
  right: -2px;
}
.ribbon span::before {
  content: '';
  position: absolute;
  left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
.ribbon span::after {
  content: '';
  position: absolute;
  right: 0%; top: 100%;
  z-index: -1;
  border-right: 3px solid #79A70A;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
.red span {background: linear-gradient(#F70505 0%, #8F0808 100%);}
.red span::before {border-left-color: #8F0808; border-top-color: #8F0808;}
.red span::after {border-right-color: #8F0808; border-top-color: #8F0808;}

.blue span {background: linear-gradient(#2989d8 0%, #1e5799 100%);}
.blue span::before {border-left-color: #1e5799; border-top-color: #1e5799;}
.blue span::after {border-right-color: #1e5799; border-top-color: #1e5799;}

.offer-job-item {
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

#map {
  height: 190px;
}

.customPreviousBtn {
  width: 64px;
  position: absolute;
  top: 375px;
  z-index: 1000;
  cursor: pointer;
  left: 15px;
}
.customNextBtn {
  width: 64px;
  position: absolute;
  top : 375px;
  z-index: 1000;
  cursor: pointer;
  right: 15px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .customPreviousBtn {
    width: 64px;
    position: absolute;
    top: 75px;
    z-index: 1000;
    cursor: pointer;
    left: 15px;
  }
  .customNextBtn {
    width: 64px;
    position: absolute;
    top : 75px;
    z-index: 1000;
    cursor: pointer;
    right: 15px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .customPreviousBtn {
    width: 64px;
    position: absolute;
    top: 75px;
    z-index: 1000;
    cursor: pointer;
    left: 15px;
  }
  .customNextBtn {
    width: 64px;
    position: absolute;
    top : 75px;
    z-index: 1000;
    cursor: pointer;
    right: 15px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .customPreviousBtn {
    width: 64px;
    position: absolute;
    top: 75px;
    z-index: 1000;
    cursor: pointer;
    left: 15px;
  }
  .customNextBtn {
    width: 64px;
    position: absolute;
    top : 375px;
    z-index: 1000;
    cursor: pointer;
    right: 15px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .customPreviousBtn {
    width: 64px;
    position: absolute;
    top: 375px;
    z-index: 1000;
    cursor: pointer;
    left: 15px;
  }
  .customNextBtn {
    width: 64px;
    position: absolute;
    top : 375px;
    z-index: 1000;
    cursor: pointer;
    right: 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .customPreviousBtn {
    width: 64px;
    position: absolute;
    top: 275px;
    z-index: 1000;
    cursor: pointer;
    left: 15px;
  }
  .customNextBtn {
    width: 64px;
    position: absolute;
    top : 275px;
    z-index: 1000;
    cursor: pointer;
    right: 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1900px) {
  .home-slider .item {
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: cover;
    height: 650px;
  }
}

#logo_loader {
  position: relative;
  top: 50px;
  text-align: center;
}

#logo_loader img {
  height: 50%;
}

.add-shadow {
  box-shadow: 5px 5px 15px 10px #E3E3E3;
}

.img-middle {
  display: inline-block;
}

/* Pricing table */
.background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
}

.background::after {
  content: '';
  background: #60a9ff;
  background: -moz-linear-gradient(top, #60a9ff 0%, #4394f4 100%);
  background: -webkit-linear-gradient(top, #60a9ff 0%,#4394f4 100%);
  background: linear-gradient(to bottom, #60a9ff 0%,#4394f4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60a9ff', endColorstr='#4394f4',GradientType=0 );
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media (min-width: 900px) {
  .background {
    padding: 0 0 25px;
  }
}

.container-pricing-table {
  margin: 0 auto;
  padding: 50px 50px 0 50px;
  /*max-width: 960px;*/
  /*display: flex;*/
  display: inline-block;
  width: 100%;
  /*font-family: 'Open Sans', sans-serif !important;*/
}

@media (min-width: 1024px) {
  .container-pricing-table {
    display: flex;
  }
}

.panel {
  background-color: #fff;
  border-radius: 0 !important;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

@media (max-width: 1024px) {
  .pricing-table {
    /*flex-direction: row;*/
    margin-bottom: 20px !important;
  }
}

.pricing-table * {
  text-align: center;
}

.pricing-table .push-right {
  text-align: left !important;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    /*border-right: 1px solid #e1f1ff;*/
    flex-basis: 100%;
    padding: 25px 50px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  color: #016FF9;
  /*font-weight: 600;*/
  letter-spacing: 1px;
  margin: 50px 0 25px;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  display: flex;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
  color: #016FF9;
  display: block;
  font-size: 32px;
  font-weight: 700;
}

.pricing-button {
  border: 1px solid #9dd1ff;
  border-radius: 10px;
  color: #348EFE;
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-featured {
  background-color: #48aaff;
  color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  background-color: #269aff;
}

.pricing-text-title {
  color: #1A3661;
}

.pricing-table .section-title h4 {
  font-size: 25px;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 20px;
  display: inline-block;
}
.pricing-table .section-title h4::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 2px;
  background-color: #d8d8d8;
  bottom: 0;
  left: 50%;
  margin-left: -40px;
}
.pricing-table .section-title h4::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background-color: #29B6F6;
  /*left: 0;*/
  bottom: 0;
  left: 50%;
  margin-left: -25px;
}

/* blog post */
.blog-post .pb-100 {
  padding-bottom: 100px;
}
.blog-post .pt-100 {
  padding-top: 100px;
}
.blog-post .pt-20 {
  padding-top: 100px;
}
.blog-post .mb-100 {
  margin-bottom: 100px;
}
.blog-post a {
  text-decoration: none;
  color: #333;
  -webkit-transition: .4s;
  transition: .4s;
}
.blog-post .section-title {
  position: relative;
}
.blog-post .section-title p {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 400;
}
.blog-post .section-title h4 {
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 20px;
  display: inline-block;
}
.blog-post .section-title h4::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 2px;
  background-color: #d8d8d8;
  bottom: 0;
  left: 50%;
  margin-left: -40px;
}
.blog-post .section-title h4::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background-color: #29B6F6;
  /*left: 0;*/
  bottom: 0;
  left: 50%;
  margin-left: -25px;
}
.blog-post .blog-img {
  position: relative;
}
.blog-post .blog-img img {
  width: 100%;
}
.blog-post .post-category a {
  display: inline-block;
  background-color: #1A3661;
  color: #fff;
  font-size: 15px;
  padding: 5px 20px;
}
.blog-post .single-blog:hover img {
  opacity: .85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)"
}
.blog-post .post-category {
  position: absolute;
  left: 0;
  bottom: 0;
}
.blog-post .blog-content {
  padding: 30px 20px;
}
.blog-post .single-blog {
  border: 1px solid #eee;
}
.blog-post .blog-title h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.blog-post .meta ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-post .meta {
  margin-bottom: 20px;
  opacity: .85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
}
.blog-post .blog-content a.box_btn {
  display: inline-block;
  background-color: #29B6F6;
  padding: 5px 15px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 20px;
}
.blog-post a.box_btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #1A3661;
  left: -100%;
  top: 0;
  -webkit-transition: .5s;
  transition: .5s;
  z-index: -1;
}
.blog-post a.box_btn {
  overflow: hidden;
  z-index: 2;
  -webkit-transition: .5s;
  transition: .5s;
  position: relative;
  text-decoration:none;
}
.blog-post a.box_btn:hover::before {
  left: 0;
  z-index: -1;
}

#ask-quote-skills-acquisition .modal-header {
    display: flex;
}

#ask-quote-skills-acquisition .disp-content {
    display: contents;
}

#ask-quote-skills-acquisition .blue-level-one-htc {
    background-color: #4a98f6;
}

#ask-quote-skills-acquisition .text-white-modal {
    color: white !important;
}

#ask-quote-counseling-and-carrer-development .modal-header {
  display: flex;
}

#ask-quote-counseling-and-carrer-development .disp-content {
  display: contents;
}

#ask-quote-counseling-and-carrer-development .blue-level-one-htc {
  background-color: #4a98f6;
}

#ask-quote-counseling-and-carrer-development .text-white-modal {
  color: white !important;
}

#ask-quote-evaluation .modal-header {
  display: flex;
}

#ask-quote-evaluation .disp-content {
  display: contents;
}

#ask-quote-evaluation .blue-level-one-htc {
  background-color: #4a98f6;
}

#ask-quote-evaluation .text-white-modal {
  color: white !important;
}

.pricing-title-plan b {
  color: #000000;
}

.pricing-features b {
  font-weight: 750;
}

.mab-20 {
  margin-bottom: 20px;
}

a.img-container-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

a.img-container-custom img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

/*@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: transparent;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 150px;
  position: absolute;
  width: auto;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 15s linear infinite;
  display: flex;
  width: calc(250px * 25);
}
/*.slider .slide {
  height: 100px;
  width: 100px;
}*/

.slider {
  overflow: hidden;
  white-space: nowrap;
}

.slide-track-left {
  display: inline-block;
  animation: scroll-left 30s linear infinite;
  will-change: transform;
}

.slide-track-right {
  display: inline-block;
  animation: scroll-right 60s linear infinite;
  will-change: transform;
}

.slide {
  display: inline-block;
  width: 150px;
  margin-right: 20px;
}

@keyframes scroll-left {
  100% { transform: translateX(0); }
  0% { transform: translateX(calc(-150px * 25)); }
}

@keyframes scroll-right {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-150px * 25)); }
}